/* You can add global styles to this file, and also import other style files */

@import "./palette.scss";
@import "./mixins.scss";

/** Material Design font and theme */
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");
@import url("https://fonts.googleapis.com/css?family=Play&display=swap");

@import "./theme.scss";

/** ag-grid is much more user friendly than tables :) **/
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-alpine.css';

@import "@material/theme/mdc-theme";
@import "@material/theme/mixins";

* {
  font-family: "Roboto", sans-serif;
  outline: none;
}

html,
body {
  height: 100%;
  margin: 0;
}

body {
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: border-box; // 1
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Play", sans-serif;
  font-weight: 500;
  line-height: 1.2;
}
/** Material specific fixes */
h1 {
  font-size: 3rem;
  @media screen and (min-width: 960px) {
    font-size: 4.5rem;
  }
  margin: 1rem 0 2rem;
  color: #4e4e4e;
}
h2 {
  font-size: 2.2rem;
  @media screen and (min-width: 960px) {
    font-size: 3rem;
  }
  margin: 1rem 0 2rem;
  color: #4e4e4e;
}
h3 {
  font-size: 1.85rem;
  @media screen and (min-width: 960px) {
    font-size: 2.25rem;
  }
  margin: 1rem 0 2rem;
  color: #4e4e4e;
}
h4 {
  font-size: 1.25rem;
  @media screen and (min-width: 960px) {
    font-size: 1.5rem;
  }
  font-weight: 600;
  margin: 1rem 0 2rem;
  color: #4e4e4e;
}
h5 {
  font-size: 1.15rem;
  @media screen and (min-width: 960px) {
    font-size: 1.25rem;
  }
  margin: 1rem 0 2rem;
  color: #4e4e4e;
}
h6 {
  font-size: 1.1rem;
  @media screen and (min-width: 960px) {
    font-size: 1.1rem;
  }
  margin: 1rem 0 2rem;
  color: #4e4e4e;
}
ul {
  font-size: 0.95em;
  li {
    font-size: 0.95em;
  }
}

p {
  line-height: 1.75rem;
  margin-bottom: 1.75rem;
  word-wrap: break-word;
  display: block;
  color: #414549;
  font-size: 0.95em;
  &:last-child {
    margin: 0;
  }
  img {
    max-width: 400px;
  }
}
img[src$="#img-bestuursleden"] {
  width: 500px;
}

img[src$="#img-bestuurgroepsfoto"] {
  max-width: 100% !important;
  min-width: 100% !important;
}

li {
  line-height: 2rem;
}

a {
  color: orca-color("primary");
}

.title-with-icon {
  display: flex !important;
  align-items: center;
  mat-icon {
    margin-right: 10px;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card-title {
  font-size: 16px !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.mat-card-header-text {
  margin: 0 !important;
}
.mat-success {
  background-color: #3eb111;
  color: #fff;
}
a {
  color: orca-color("primary");
  text-decoration: none;
  background-color: transparent;
  &:hover {
    text-decoration: underline;
  }
}

.alert {
  border: 0;
  margin: 10px 0;
  padding: 20px 20px;
  border-radius: 5px;
}
.no-padding-dialog .mat-mdc-dialog-container {
  padding: 0;
}

mat-form-field {
  width: 100%;
}

.mat-mdc-card-content .rowing-ban-text p {
  color: white;
}

// FIX https://github.com/h2qutc/angular-material-components/issues/348
.mat-datepicker-content {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  background-color: #fff;
  color: #000000de
}

.mat-calendar-body-selected {
  background-color: #d81e05;
  color: #fff;
}

.mat-calendar-body-disabled {
  color: lightgray;
}

.mat-calendar-body-cell-content {
  border: none !important;
}
// END FIX https://github.com/h2qutc/angular-material-components/issues/348

//Markdown specific

markdown {
  img[src$="#hyves-emoji"] {
    max-width: 80px;
    max-height: 80px;
  }
}

// For in ag grid
.has-arrived-payed {
  background: orca-color("green") !important;
  color: white !important;
}
.has-arrived {
  background: orca-color("orange") !important;
  color: white !important;
}
.has-payed {
  background: orca-color("blue") !important;
  color: white !important;
}
.ag-row-selected {
  opacity: 0.9;
}
.ag-row-focus {
  opacity: 0.85;
}

.ag-cell-edit-wrapper {
  color: black;
}

.CodeMirror-scroll {
  height: unset !important; // Fixes overflowing markdown editor
}

// Remove most default styles from button, so that it can be used as an accessible wrapper for clickable stuff. 
button {
  border: none;
  background-color: transparent;
  &:hover {
    text-decoration: underline;
  }
}